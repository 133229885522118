// GTM.tsx
'use client';
import React, { useEffect } from 'react';
import Script from 'next/script';
import { usePathname } from 'next/navigation';
import { pageview } from '@ui/utils/gtm';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

interface FaviconRule {
  destination: string;
  hosts: string[];
}

const faviconRules: FaviconRule[] = [
  {
    destination: `${process.env.NEXT_PUBLIC_BASE_PATH || ''}/images/wowway/favicon.ico`,
    hosts: ['wowway.com', 'marketing-override.wowway.com', 'wow-marketing-residential', 'localhost']
  },
  {
    destination: `${process.env.NEXT_PUBLIC_BASE_PATH || ''}/images/wowforbusiness/favicon.ico`,
    hosts: [
      'wowforbusiness.com',
      'marketing-override.wowforbusiness.com',
      'wow-marketing',
      'wow-marketing-biz.vercel.app'
    ]
  }
];

function getFaviconUrl(): string {
  const currentHost = typeof window !== 'undefined' ? window.location.hostname : '';

  for (const rule of faviconRules) {
    for (const host of rule.hosts) {
      if (currentHost.includes(host)) {
        return rule.destination;
      }
    }
  }

  // Fallback to default favicon URL if no rule matches
  return '/favicon.ico';
}

const GTM: React.FC = () => {
  const pathname = usePathname();
  const faviconUrl = getFaviconUrl();

  useEffect(() => {
    if (pathname) {
      pageview(pathname);
    }
  }, [pathname]);

  if (!GTM_ID) {
    return null;
  }

  return (
    <>
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <link rel="shortcut icon" href={faviconUrl} />
      {process.env.NEXT_PUBLIC_OPTIMIZELY_ID ? (
        <Script src={`https://cdn.optimizely.com/js/${process.env.NEXT_PUBLIC_OPTIMIZELY_ID}.js`} />
      ) : null}
      {GTM_ID ? (
        <>
          <link rel="preconnect" href="https://www.googletagmanager.com" />
          <Script
            id="GTM_TAG"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');
              `
            }}
          />
          <noscript>
            <iframe
              title="GTM-NOSCRIPT"
              src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </>
      ) : null}
    </>
  );
};

export default GTM;
